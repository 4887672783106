@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");

* {
  font-family: "Outfit", sans-serif !important;
  font-optical-sizing: auto;
}


@tailwind base;
@tailwind components;
@tailwind utilities;

.flow-hide {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.flow-hide-x-1 {
  overflow-x: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

.flow-hide-x {
  overflow-x: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.flow-hide-x::-webkit-scrollbar {
  width: 0;
  height: 0;
  cursor: pointer;
}
.flow-hide::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}
.hide-scroll {
  scrollbar-width: none !important; /* Firefox */
  -ms-overflow-style: none !important; /* Internet Explorer 10+ */
}
.hide-scroll::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}

.z-high {
  z-index: 1000 !important;
}

.login-box {
  background: #f9f9f9;
  box-shadow: 6px 5px 24px rgba(0, 0, 0, 0.17);
}

.cap {
  background: #d3a518;
  border-radius: 5px 5px 0px 0px;
  height: 16px;
}
.card-cap {
  background: #041c40;
  border-radius: 10px 10px 0px 0px;
  height: 16px;
}
.v-pay-grad {
  border-radius: 6px;
  background: var(
    --VERSUSPAY-GRADIENT,
    linear-gradient(64deg, #2f80ec -19.7%, #8364f6 86.76%)
  );
}
.grad-bg {
  background: var(
    --VERSUSPAY-GRADIENT,
    linear-gradient(64deg, #2f80ec -19.7%, #8364f6 86.76%)
  );
}

.brief-card-bg {
  background: #f9f9f9;
  box-shadow: 6px 5px 24px rgba(0, 0, 0, 0.17);
  border-radius: 10px;
}

.arrow-circle {
  background: #ffffff;
  box-shadow: 0px 2.61743px 13.0872px rgba(217, 217, 217, 0.25);
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
}
.z-1 {
  z-index: 1;
}
.z-2 {
  z-index: 2;
}
.z-max {
  z-index: 100 !important;
}

.conta {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  margin-top: 30px;
  width: 100%;
  height: 12rem;
  border-radius: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("/src/assets/png/OBJECTS.png");
  background-color: #2f80ec;
  background-size: 100%;
  color: #ffffff;
}

.center {
  width: 30%;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  margin-left: 30px;
}
